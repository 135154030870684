#hero {
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

#hero video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

#hero #hero-content-container {
  width: 45%;
  min-width: 600px;
  gap: 1.5rem;
  margin: 0 auto;
}

#hero #hero-content-container #hero-text {
  font-size: 2.6rem;
  font-weight: 500;
}

#hero #search-form {
  background-color: #fff;
  border-radius: 33px;
  gap: .25rem;
  padding: 3px;
  overflow-x: hidden;
}

#hero #search-form > :first-child {
  border-right: 1px solid var(--bs-primary);
}

#hero #search-form label {
  align-items: center;
  padding: 0 .4rem;
  display: flex;
}

/*# sourceMappingURL=hero.css.map */
