#hero {
    position: relative;
    height: 100vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        transform: translateX(-50%) translateY(-50%);
    }
    #hero-content-container {
        width: 45%;
        min-width: 600px;
        margin: 0 auto;
        gap: 1.5rem;
        #hero-text {
            font-size: 2.6rem;
            font-weight: 500;
        }
    }

    #search-form {
        background-color: #fff;
        border-radius: 33px;
        padding: 3px;
        overflow-x: hidden;
        gap: .25rem;

        & > :first-child {
            border-right: 1px solid var(--bs-primary);
        }
        label {
            display: flex;
            align-items: center;
            padding: 0 .40rem;
        }
    }
}